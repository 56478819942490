import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Category as CategoryIcon,
  CloudUpload as CloudUploadIcon,
  Comment as CommentIcon,
  Pages as PagesIcon,
  Book as BookIcon,
  ListAltRounded as ListAltRounded,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure2 = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  {
    id: 1,
    label: "Blog Category",
    link: "/app/blog_catagory",
    icon: <CategoryIcon />,
  },
  {
    id: 2,
    label: "Blog Upload",
    link: "/app/blog_upload",
    icon: <CloudUploadIcon />,
  },
  {
    id: 10,
    label: "Book Upload",
    link: "/app/book",
    icon: <BookIcon />,
  },
];

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // {
  //   id: 1,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 3,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  {
    id: 1,
    label: "Blog Category",
    link: "/app/blog_catagory",
    icon: <CategoryIcon />,
  },
  {
    id: 2,
    label: "Blog Upload",
    link: "/app/blog_upload",
    icon: <CloudUploadIcon />,
  },
  // {
  //   id: 3,
  //   label: "Page Mata",
  //   link: "/app/page_meta",
  //   icon: <PagesIcon />,
  // },
  // {
  //   id: 4,
  //   label: "Baner Section",
  //   link: "/app/Banersection",
  //   icon: <CloudUploadIcon />,
  // },
  {
    id: 5,
    label: "Contact",
    link: "/app/contact",
    icon: <CommentIcon />,
  },
  {
    id: 5,
    label: "UnsubmitContact",
    link: "/app/unsubmitcontact",
    icon: <CommentIcon />,
  },
  {
    id: 6,
    label: "BlocksentialsContact",
    link: "/app/blocksentialcontacts",
    icon: <CommentIcon />,
  },
  {
    id: 6,
    label: "Beleaf Blog Contacts",
    link: "/app/beleafblogcontact",
    icon: <CommentIcon />,
  },
  {
    id: 6,
    label: "Blocksentials Blog Contacts",
    link: "/app/blocksentinalsblogcontact",
    icon: <CommentIcon />,
  },
  {
    id: 7,
    label: "Subscribe",
    link: "/app/Subscribe",
    icon: <CommentIcon />,
  },
  {
    id: 8,
    label: "Career",
    link: "/app/career",
    icon: <CommentIcon />,
  },
  {
    id: 12,
    label: "Employee List",
    link: "/app/employeedata",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Job Opening Form",
    link: "/app/job",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Intern Opening Form",
    link: "/app/internshipers",
    icon: <ListAltRounded />,
  },

  {
    id: 12,
    label: "Intern Applyers",
    link: "/app/jobapplyerlist",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Job Applyers",
    link: "/app/internapplyerlist",
    icon: <ListAltRounded />,
  },

  // {
  //   id: 9,
  //   label: "Service",
  //   link: "/app/service_list",
  //   icon: <NotificationsIcon />,
  // },

  {
    id: 10,
    label: "Coursecontact",
    link: "/app/coursecontact",
    icon: <NotificationsIcon />,
  },
  // {
  //   id: 11,
  //   label: "Book Upload",
  //   link: "/app/book",
  //   icon: <BookIcon />,
  // },

  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },
  // { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  // { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="large" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="large" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="large" color="secondary" />,
  // },
];

const structure3 = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  {
    id: 12,
    label: "Employee List",
    link: "/app/employeedata",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Job Opening Form",
    link: "/app/job",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Intern Opening Form",
    link: "/app/internshipers",
    icon: <ListAltRounded />,
  },

  {
    id: 12,
    label: "Intern Applyers",
    link: "/app/jobapplyerlist",
    icon: <ListAltRounded />,
  },
  {
    id: 12,
    label: "Job Applyers",
    link: "/app/internapplyerlist",
    icon: <ListAltRounded />,
  },

  {
    id: 10,
    label: "Coursecontact",
    link: "/app/coursecontact",
    icon: <NotificationsIcon />,
  },
];
function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [user, setuser] = useState("");

  useEffect(function () {
    var id = localStorage.getItem("user_token");
    console.log(id, "id=--=-=-=-=id=-=-=-id");
    setuser(id);
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      {user == "BeleafTechnologies" || user == "beleaf" ? (
        <List className={classes.sidebarList}>
          {structure.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      ) : user === "ADMINHR" ? (
        <List className={classes.sidebarList}>
          {structure3.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      ) : (
        <List className={classes.sidebarList}>
          {structure2.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      )}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
