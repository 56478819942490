const service = {
  login: "/adminapi/login",
  getEnquiryList: "/adminapi/getenquirylist",
  saveBlogName: "/adminapi/saveblogname",
  get_blog_name_list: "/adminapi/get_blog_name_list",
  getcoursecotactlist: "/adminapi/getcoursecotactlist",
  get_blog_category_list: "/adminapi/blog_category_list",
  get_catagary_url: "/adminapi/get_catagary_url",
  updateBlogName: "/adminapi/updateblogname",
  getcontacts: "/adminapi/getcontacts",
  getunsubmitcontacts: "/adminapi/getunsubmitcontacts",
  get_beleaf_blog_contacts: "/blog/get_beleaf_blog_contacts",
  get_Blocksentels_blog_contacts: "/blog/get_Blocksentels_blog_contacts",
  changestatus: "/adminapi/changestatus",
  careerdata: "/adminapi/getcareer",
  changecareerstatus: "/adminapi/changecareerstatus",
  saveBlog: "/adminapi/saveblog",
  editBlog: "/adminapi/editblog",
  get_blog_list: "/adminapi/get_blog_list",
  BanerSection: "/adminapi/BanerSection",
  getBanerSection: "/adminapi/getBanerSection",
  editBanerSection: "/adminapi/editBanerSection",
  deleteBanerSection: "/adminapi/deleteBanerSection",
  geteditBaner: "/adminapi/geteditBaner",
  saveService: "/adminapi/saveService",
  editDatas: "/adminapi/editDatas",
  deleteDatas: "/adminapi/deleteDatas",
  editService: "/adminapi/editService",
  ChangePassword: "/adminapi/ChangePassword",
  Sitesetting: "/adminapi/Sitesetting",
  Subscribelist: "/adminapi/Subscribelist",
  getBlocksentelSubscribelist: "/adminapi/getBlocksentelSubscribelist",
  get_catagary_list: "/adminapi/get_catagary_list",
  get_login_history: "/adminapi/get_login_history",
  uploadBook: "/adminapi/uploadBook",
  getBookList: "/adminapi/getBookList",
  downloaderList: "/adminapi/downloaderList",
  upload: "/adminapi/upload",
  deleteblog: "adminapi/deleteblog",
  //Blocksentinels
  getBlocksentialscontacts: "/adminapi/getBlocksentialscontactsL",
  get_Blocksentinal_blog_List: "/adminapi/get_Blocksentinal_blog_List",
  getBlockSentinalList: "/adminapi/getBlockSentinalList",

  //EMPLOYEEDETAILS
  submit_Employee_details: "/adminapi/submit_Employee_details",
  get_employe_details: "/adminapi/get_employe_details",
  delete_employe_details: "/adminapi/delete_employe_details",
  edit_employe_details: "/adminapi/edit_employe_details",

  //CARREERiNTERFORM
  submit_Intern_Details: "/adminapi/submit_Intern_Details",
  get_Intern_Details: "/adminapi/get_Intern_Details",
  delete_Intern_Details: "/adminapi/delete_Intern_Details",
  edit_Intern_Details: "/adminapi/edit_Intern_Details",
  get_beleaf_inter_list: "/adminapi/get_beleaf_inter_list",
  get_beleaf_Job_applyers: "/adminapi/get_beleaf_Job_applyers",

  //CARREERJOBADMIN
  submit_job_Details: "/adminapi/submit_job_Details",
  get_job_Details: "/adminapi/get_job_Details",
  delete_job_Details: "/adminapi/delete_job_Details",
  edit_job_Details: "/adminapi/edit_job_Details",
};

export default service;
