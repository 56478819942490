import React, { useEffect } from "react";

import useState from "react-usestateref";

import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
import moment from "moment";

function UnsubmitContact() {
  const [currentPage, setCurrentPage] = useState(1);
  const [getUnsubmitContacts, setgetUnsubmitContacts, getUnsubmitContactsref] =
    useState([]);
  const [totalPage, setTotalpages] = useState(0);

  const recordPerPage = 10;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    getContacts(pageNumber);
    setCurrentPage(pageNumber);
    // setchoosepage()
  };

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async (page) => {
    try {
      var payload = {
        perpage: 10,
        page: page,
        search: "btc",
      };
      var data = {
        apiUrl: apiService.getunsubmitcontacts,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp.data.data, "resp.data.data");
        // return;
        setgetUnsubmitContacts(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  return (
    <div>
      {" "}
      Beleaf Unsubmit Contact
      <div className="table-responsive unsubcon_table">
        <table class="table my-2 table-striped">
          <thead>
            <tr>
              <th> S.No</th>
              <th> Username </th>
              <th> Country</th>
              <th> Country Code </th>
              <th> Mobile Number</th>
              <th> Medium </th>
              <th> Medium Detail </th>
              <th> Message </th>
              <th> Date </th>
            </tr>
          </thead>

          <tbody>
            {getUnsubmitContactsref.current &&
              getUnsubmitContactsref.current.map((orders, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{orders.userName}</td>
                    <td>{orders.userCountry}</td>
                    <td>{orders.userCountryCode}</td>
                    <td>{orders.userMobilenumber}</td>
                    <td>{orders.contactMedium}</td>
                    <td>{orders.mediumDetail}</td>
                    <td className="wrap_unsub">{orders.userMessage}</td>
                    <td>{moment(orders.createdAt).format("lll")}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Pagination
        itemClass="page-item" // add it for bootstrap 4
        linkClass="page-link" // add it for bootstrap 4
        activePage={currentPage}
        itemsCountPerPage={recordPerPage}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default UnsubmitContact;
